<template>
    <div>           
        <b-card class="mb-3" body-class="pb-3">
            <b-row class="app-card-header mb-3">
                <b-col start>
                    <div class="app-card-tittle">
                        <div> {{trans('cp-prices',190)}} {{cItemData.uzk_parameters.calculation_type == 1 ? trans('cw-minus-hourly-rate',194) : trans('cw-minus-conversion-factor',194)}}</div>    
                    </div>
                </b-col>
                <b-col cols="auto" end>
                    <b-spinner class="align-middle" small v-if="loading_functions"></b-spinner>  
                </b-col>
            </b-row>

            <b-row class="pr-3" v-if="sort_matrix != null && sort_matrix.length > 0">
                <b-col cols="3">
                </b-col>
                <b-col align="center" v-if="cItemData.uzk_parameters.diff_assigment == 1">
                    <label class="flabel"> {{trans('broadcast',192)}} </label>
                </b-col>
                <b-col class="app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_assigment == 1">
                    <label class="flabel"> {{trans('detachment',182)}} </label>
                </b-col>
                <b-col align="center" v-if="cItemData.uzk_parameters.diff_assigment == 0">
                    <label class="flabel">{{trans('cw-temporary-employment-and-secondment',194)}}</label>
                </b-col>
            </b-row>

            <b-row class="pr-3" v-if="sort_matrix != null && sort_matrix.length > 0">
                <b-col cols="3">
                    <label class="flabel"> {{trans('function',3)}}</label>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-a',193)" labelId="p_a" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-b',193)" labelId="p_b" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-c',193)" labelId="p_c" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-all-phases',193)" labelId="p_all" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-ort',194)" labelId="ort" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-a',193)" labelId="ap_a" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-b',193)" labelId="ap_b" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-phase-c',193)" labelId="ap_c" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-all-phases',193)" labelId="ap_all" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <clients-profile-contract-wizard-av-aivg-header-component :label="trans('cw-ort',194)" labelId="a_ort" :itemData="cItemData" :copyObject.sync="copy_object" :isMenu="cItemData.indexation_type == 2"/>
                </b-col>
            </b-row>            

           <b-row class="pr-3" v-for="item in sort_matrix" :key="item.value">
                <b-col cols="3">
                    <label class="flabel font-weight-300">{{item.function_name}}</label>
                </b-col>

                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_a" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_b" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1">
                    <app-input class="mb-1" v-model="item.p_c" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0">
                    <app-input class="mb-1" v-model="item.p_all" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1">
                    <app-input class="mb-1" v-model="item.ort" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_a" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_b" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_c" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.diff_phase == 0 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.ap_all" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
                <b-col class="pl-1 pr-1 app-local-bg-assigment" align="center" v-if="cItemData.uzk_parameters.calculation_type == 2 && cItemData.uzk_parameters.diff_overhour == 1 && cItemData.uzk_parameters.diff_assigment == 1">
                    <app-input class="mb-1" v-model="item.a_ort" :type="cType" :key="refresh" :disabled="cItemData.indexation_type != 2"/>
                </b-col>
            </b-row>      

            <b-alert variant="light" show class="p-3 mb-3 app-local-height d-flex align-items-center" v-if="sort_matrix == null || (sort_matrix != null && sort_matrix.length == 0) ">
                <div v-if="loading_functions" class="text-small">{{trans('cw-load-functions',194)}}</div>
            </b-alert> 
        </b-card>
        
        <b-row class="match-height" v-if="cItemData.km_allow == 1">
            <b-col class="pl-md-2">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('travel-costs',192)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-radio-group v-model="cItemData.km_allow" :options="opt" :label="trans('cw-expenses-reimbursed',194)" :disabled="true"/>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" v-if="cItemData.km_allow == 1">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.cost_house_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )" :label="trans('cw-home-work',194)" type="decimal_01"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="cItemData.cost_work_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null) )" :label="trans('cw-work-work',194)" type="decimal_01"/>
                        </template>
                    </app-row-left-label>    
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import axios from "@axios";
import ClientsProfileContractWizardAvAivgHeaderComponent from './Wizard/Step2/Components/ClientsProfileContractWizardAvAivgHeaderComponent.vue';

export default {
    props:['itemData'],

    components:{
        ClientsProfileContractWizardAvAivgHeaderComponent
        
    },

    created(){
        this.user_data = JSON.parse(localStorage.getItem('user'));
        this.sort_matrix = this.cItemData.uzk_parameters.functions;
        this.default_values = JSON.parse(JSON.stringify(this.cItemData.uzk_parameters.functions));

    },

    watch: {

        "copy_object.refresh":{
            handler: function(val) {
               this.copyValues();                
            }
        },

        "itemData.percent": {
            handler: function(val){
                this.calculateIndexation();
            }
        }
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },
        cType: {
            get() {
                if(this.cItemData.uzk_parameters.calculation_type == 1 && this.cItemData.uzk_parameters.diff_assigment == 0){
                    return 'decimal_02';
                }
                else if(this.cItemData.uzk_parameters.calculation_type == 2 && this.cItemData.uzk_parameters.diff_assigment == 0){
                    return 'decimal_01_places_04';
                } else {
                    return 'decimal_03';
                }

            }
        }
    },

    data(){
        return{
            loading_functions: false,
            disabled_days_before: null,
            disabledDatesRange: null,
            min_date: null,
            items: null,
            refresh: 0,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
            user_data: null,
            sort_matrix: [],
            copy_object: {
                column_from: null,
                column_to: null,
                operation_type : null,
                refresh: 0,
            },
            default_values: [],
            
        }
    },


    methods: {

        copyValues() {
            if (this.copy_object.operation_type == 1) { // copy first to all
                let source_value = this.sort_matrix[0][this.copy_object.column_from];
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_from] = source_value;
                });
                
                this.refresh++;

            } else if (this.copy_object.operation_type == 2) { // copy column to column
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_to] = item[this.copy_object.column_from];
                });
                
                this.refresh++;

            } else if (this.copy_object.operation_type == 3) { // copy shift to assigment
                this.sort_matrix.forEach(item => {
                    item["ap_a"] = item["p_a"];
                    item["ap_b"] = item["p_b"];
                    item["ap_c"] = item["p_c"];
                    item["ap_all"] = item["p_all"];
                    item["a_ort"] = item["ort"];
                });

                this.refresh++;
            } else if (this.copy_object.operation_type == 4) { // clear column
                
                this.sort_matrix.forEach(item => {
                    item[this.copy_object.column_from] = null;
                });
                
                this.refresh++;
            }
        },

        calculateIndexation(){

                this.sort_matrix.forEach((item, i) => {

                    var keys = Object.keys(item);

                    keys.forEach(key => {
                        if(key != 'function_name' && key != 'id' && item[key] != null){

                            item[key] = (parseFloat(this.default_values[i][key].replace(',','.')) + parseFloat(this.default_values[i][key].replace(',','.')) * (parseFloat(this.itemData.percent != "" ? this.itemData.percent : 0)/100)).toString();
                        }
                    });
                });
       
        },

        showDescription() {
            this.$root.$emit("bv::show::popover", "popover-id-function");
        },

    }

    
}
</script>

<style scoped>
.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}

.app-local-bg-assigment {
background-color: #F9FAFB;
}

</style>