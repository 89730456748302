<template>
    <b-row>
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">
            <b-skeleton-wrapper :loading="loading_list">
                <template #loading>
                    <b-card class="mb-3">
                        <b-skeleton height="140px"></b-skeleton>
                    </b-card>

                    <b-card class="mb-3">
                        <b-skeleton height="300px"></b-skeleton>
                    </b-card>

                    <b-card class="">
                        <b-skeleton height="140px"></b-skeleton>
                    </b-card>
                </template>

                <ValidationObserver ref="form">

                    <b-card class="mb-3" body-class="pb-3" v-if="no_params != true">
                        <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                            <template v-slot:col-1>
                                <app-radio-group v-model="item_data.indexation_type" :label="trans('cw-indexation-type',194)" :options="opt" validatorRules="required" :validatorName="trans('cw-indexation-type',194)"/>
                            </template>
                            <template v-slot:col-2>
                                <app-date v-model="item_data.indexation_date_from" :label="trans('cw-indexation-date-from',194)" :validatorName="trans('cw-indexation-date-from',194)" validatorRules="required|date-check" :validatorCustomMessage="{ 'disable-date-past': trans('cw-end-date-error',194) }" :disabledDatesRange="disabledDatesRange" />
                            </template>

                        </app-row-left-label> 
                        <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                            <template v-slot:col-1>
                                <app-input v-model="item_data.percent" :disabled="item_data.indexation_type != 1" :label="trans('cw-percentage',194)" :key="refreshItems" :validatorRules="item_data.indexation_type == 1 ? 'required' : ''" :validatorName="trans('cw-percentage',194)" type="decimal_01"/>
                            </template>
                
                        </app-row-left-label> 
                    </b-card>
                    
                    <clients-profile-organization-contract-indexation-UZK :itemData.sync="item_data" v-if="item_data.contract_type === 1"/>
                    <clients-profile-organization-contract-indexation-ZZP :itemData.sync="item_data" v-else-if="item_data.contract_type === 2"/>
                
                </ValidationObserver>

                <div class="mt-4 mb-3" align="left" v-if="no_params != true">
                    <app-button type="primary"  @click="saveContract" :disabled="loading_indexation" :loading="loading_indexation">{{trans('save',175)}}</app-button>
                </div>
        
            </b-skeleton-wrapper>
        </b-col>
    </b-row>   
</template>
<script>

import ClientsProfileOrganizationContractIndexationUZK from "./ClientsProfileOrganizationContractIndexationUZK.vue";
import ClientsProfileOrganizationContractIndexationZZP from "./ClientsProfileOrganizationContractIndexationZZP.vue";
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {

    components: {
        ClientsProfileOrganizationContractIndexationUZK,
        ClientsProfileOrganizationContractIndexationZZP,
        ValidationObserver
    },

    created(){
        this.getData();
    },

    watch: {
      "item_data.indexation_type": {
            handler: function(val) {
                if (val == 2) {
                    this.item_data.percent = null;
                    this.refreshItems++;
                }                
            },
            deep: true,
        },
    },

    data() {
        return {
            loading_list: true,
            loading_indexation: false,
            id_contract_data_main: this.$route.params.id_contract_data_main,
            item_data:{},
            opt: [ { value: 1, text: this.trans('cw-percentage',194) }, { value: 2, text: this.trans('cw-individually',194) }],
            no_params: false,
            disabledDatesRange: null,
            refreshItems: 0
        }
    },
    methods:{
        getData(){
            axios 
                .post("clients/profile/contract/getContract",{
                    id_contract_data_main: this.id_contract_data_main,

                })
                .then((res) => {
                    if(res.data == 2){
                        this.no_params = true;
                    }else{
                        this.item_data = res.data;
                        this.disabledDatesRange = '(date >= new Date('+moment(this.item_data.date_to,"DD-MM-YYYY")+')) || (date <= new Date('+moment(this.item_data.parameters_date_from,"DD-MM-YYYY")+'))';
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        saveContract(){
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                this.loading_indexation = true;

                axios 
                    .post("clients/profile/contract/indexation",{
                        item_data: JSON.stringify(this.item_data),
                        id_contract_data_main: this.id_contract_data_main,
                        id_contract_data_parameters: this.item_data.id_contract_data_parameters
                    })
                    .then((res) => {
                        this.loading_indexation = false;
                        this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: res.data } });
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        
                    });

             })
        },


    },
    
}
</script>
<style scoped>

</style>