<template>
    <div>
        <b-card class="mb-3" body-class="pb-3">
            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mb-2">
                <template v-slot:col-1>
                    <app-input v-model="cItemData.zzp_parameters.send_rate" :label="trans('cp-broadcast-agency-rate',190)" validatorName="Bemiddelingstarief uitzenden" validatorRules="required" type="decimal_02"/>
                </template>
                <template v-slot:col-2>
                    <app-input v-model="cItemData.zzp_parameters.detachment_rate" :label="trans('cp-mediation-rate-for-posting',190)" validatorName="Bemiddelingstarief detachering" validatorRules="required" type="decimal_02"/>
                </template>

            </app-row-left-label> 

            <hr class="app-local-line" /> 
            
            <b-row v-for="(el, index) in cRowCount" :key="el">
                
                <b-col cols="12">
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2)] != undefined">
                            <label class="flabel">{{cItemDataSelected[(index * 2)].short_name}}</label>
                        </b-col>
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2) + 1] != undefined">
                            <label class="flabel">{{cItemDataSelected[(index * 2) + 1].short_name}}</label>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12">
                    <b-row>
                        <b-col cols="3"/>                
                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2)] != undefined">
                            
                            <app-row-left-label :slots="[6, 6]">
                                <template v-slot:col-1>
                                    <label class="flabel ">{{trans('from',182)}}</label>
                                </template>
                                <template v-slot:col-2>
                                    <label class="flabel ">{{trans('until',182)}}</label>
                                </template>
                            </app-row-left-label>
                            
                        </b-col>

                        <b-col cols="4" align="center" v-if="cItemDataSelected[(index * 2) + 1] != undefined">

                            <app-row-left-label :slots="[6, 6]">
                                <template v-slot:col-1>
                                    <label class="flabel ">{{trans('from',182)}}</label>
                                </template>
                                <template v-slot:col-2>
                                    <label class="flabel ">{{trans('until',182)}}</label>
                                </template>
                            </app-row-left-label>

                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="3">
                        <app-row-left-label v-for="item in cItemData.zzp_parameters.periods.shifts" :key="item.type">
                            <label class="flabel ">{{item.display_name}}</label>
                        </app-row-left-label>
                </b-col>

                <b-col cols="4">
                    <app-row-left-label :slots="[6, 6]" v-for="item_rate in cItemDataSelected[(index * 2)].rates" :key="item_rate.id_type">
                        <template v-slot:col-1>
                            <app-input v-model="item_rate.from" :validatorName="'min_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.to != null && parseFloat(item_rate.to.replace(',', '.')) > 0 ? 'required' : '')" type="decimal_03" :validatorCustomMessage="{ 'required': trans('cw-minimum-rate-error',194) }" :disabled="cItemData.indexation_type != 2"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="item_rate.to" :validatorName="'max_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.from != null && parseFloat(item_rate.from.replace(',', '.')) > 0 ? 'app-min-value:'+parseFloat(item_rate.from.replace(',', '.')) : '')" :validatorCustomMessage="{ 'app-min-value': trans('cw-max-rate-too-low-error',194), 'required': trans('cw-maximum-rate-error',194) }" type="decimal_03" :disabled="cItemData.indexation_type != 2"/>
                        </template>
                    </app-row-left-label>
                </b-col>

                <b-col cols="4" v-if="cItemDataSelected[(index * 2) + 1] != undefined">
                    <app-row-left-label :slots="[6, 6]" v-for="item_rate in cItemDataSelected[(index * 2) + 1].rates" :key="item_rate.id_type">
                        <template v-slot:col-1>
                            <app-input v-model="item_rate.from" :validatorName="'min_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.to != null && parseFloat(item_rate.to.replace(',', '.')) > 0 ? 'required' : '')" type="decimal_03" :validatorCustomMessage="{ 'required': trans('cw-minimum-rate-error',194) }" :disabled="cItemData.indexation_type != 2"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="item_rate.to" :validatorName="'max_' + item_rate.period_name + '_' + item_rate.period_type" :validatorRules="(item_rate.from != null && parseFloat(item_rate.from.replace(',', '.')) > 0 ? 'app-min-value:'+parseFloat(item_rate.from.replace(',', '.')) : '')" :validatorCustomMessage="{ 'app-min-value': trans('cw-max-rate-too-low-error',194), 'required': trans('cw-maximum-rate-error',194) }" type="decimal_03" :disabled="cItemData.indexation_type != 2"/>
                        </template>
                    </app-row-left-label>
                </b-col>

                <hr class="app-local-line" v-if="cItemDataSelected[(index * 2) + 2] != undefined"/> 
               
            </b-row>

        </b-card>

        <b-row class="match-height" v-if="cItemData.km_allow == 1">
            <b-col md="12" class="pr-md-2 mb-sm-3 mb-md-0">
                <b-card body-class="pb-3">
                    <b-row class="app-card-header mb-3">
                        <b-col start>
                            <div class="app-card-tittle">
                                <div> {{trans('travel-costs',192)}} </div>    
                            </div>
                        </b-col>
                    </b-row>

                    <app-row-left-label>
                        <app-radio-group v-model="cItemData.km_allow" :options="opt" :label="trans('cw-expenses-reimbursed',194)" :disabled="true"/>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" v-if="cItemData.km_allow == 1">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.cost_house_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null))" :label="trans('cw-home-work',194)" type="decimal_01"/>
                        </template>
                        <template v-slot:col-2> 
                            <app-input v-model="cItemData.cost_work_work" :disabled="cItemData.km_allow == 0 || (user_data.id_user_const_role == 3 && ([0, 1].includes(cItemData.id_client_type) || cItemData.id_client_type == null))" :label="trans('cw-work-work',194)" type="decimal_01"/>
                        </template>
                    </app-row-left-label>   
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "@axios";

export default {
    props:['itemData'],

    components:{
    },

    created(){
        this.user_data = JSON.parse(localStorage.getItem('user'));

        if (this.first_load == 0) {
            this.prepareRates();
            this.first_load = 1;
        }    
    },

    mounted(){
        this.default_values = JSON.parse(JSON.stringify(this.cItemDataSelected));
    },  

    watch: {
        "itemData.percent": {
            handler: function(val){
                this.calculateIndexation();
            }
        }
    },

    computed: {
        cItemData: {
            get() {return this.itemData },
            set(value) {this.$emit('update:itemData', value) },
        },

        cItemDataSelected : {
            get() { 
                    if(this.itemData.zzp_rates != null){
                        return this.cItemData.zzp_rates.filter(({selected}) => selected == 1);
                    }else{
                        return 0;
                    }
                },
        },

        cRowCount:{
            get() {
                    
                    if(this.itemData.zzp_rates != null){
                        var selected_count = this.cItemData.zzp_rates.filter(({selected}) => selected == 1).length;
                        return parseInt(selected_count / 2) + selected_count%2;
                    }else{
                        return 0;
                    }
                
                }
        }
    },

    data(){
        return{
            first_load: 0,
            loading: false,
            user_data: null,
            selected_all: 0,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
            default_values: [],
        }
    },

    methods: {
        selectAll(){
            
            if(this.selected_all == 0){
                this.selected_all = 1;

                this.cItemData.zzp_rates.forEach((item) => {
                    item.selected = 1;
                });
                
            }else{
                this.selected_all = 0;

               this.cItemData.zzp_rates.forEach((item) => {
                    item.selected = 0;
                });

            }
        },

        selectOne(item){
            if(item.selected == 1){
                item.selected = 0;
                this.selected_all = 0;

            }else{
                item.selected = 1;
            }
            
        },

        prepareRates(){
            this.cItemData.zzp_rates.forEach((item) => {
                var id = 0;
                Object.keys(this.cItemData.zzp_parameters.rates).forEach((item_function) => {
                    if(item.id_function_data_main == item_function){
                        let items = this.cItemData.zzp_parameters.rates[item_function]
                        items.forEach((item_selected) => {
                            item.rates.push({"id_function_data_main": item_selected.id_function_data_main,
                                            "period_type": item_selected.period_type,
                                            "period_from": item_selected.period_from,
                                            "period_to": item_selected.period_to, 
                                            "period_name": item_selected.period_name,
                                            "from": item_selected.from,
                                            "to": item_selected.to});
                        });
                        item.selected = 1;
                        id = -1;
                    }else if(id == Object.keys(this.cItemData.zzp_parameters.rates).length){
                        this.cItemData.zzp_parameters.periods.shifts.forEach((item_period) => {
                            item.rates.push({"id_function_data_main": item.id_function_data_main,
                                            "period_type": item_period.type,
                                            "period_from": item_period.from,
                                            "period_to": item_period.to, 
                                            "period_name": item_period.display_name,
                                            "from": null,
                                            "to": null});
                       });
                    }
                    id++;
                });
            });
        },

        calculateIndexation(){
            
            this.cItemDataSelected.forEach( (item,i) => {
                
                item.rates.forEach( (rate, j) => {
                    if(rate.from != null) rate.from = (parseFloat(this.default_values[i].rates[j].from.replace(',','.')) + parseFloat(this.default_values[i].rates[j].from.replace(',','.')) * ((this.itemData.percent != "" ? this.itemData.percent : 0)/100)).toFixed(2).toString().replace('.',',');
                    if(rate.to != null) rate.to = (parseFloat(this.default_values[i].rates[j].to.replace(',','.')) + parseFloat(this.default_values[i].rates[j].to.replace(',','.')) * ((this.itemData.percent != "" ? this.itemData.percent : 0)/100)).toFixed(2).toString().replace('.',',');
                })
            })
        }
    }
}
</script>
<style scoped>
.app-local-btn, .app-local-btn:hover {  
    color: white !important;
    background-color: #066791;
    border-color: #066791;
    opacity: 0.5;
    margin-top: 0px !important;
}
</style>